import {
  Button,
  FlagIcon,
  Item,
  Stack,
  Text,
  useBreakpoints,
} from "@nettbureau/ui";

export const App = () => {
  const { breakpoint } = useBreakpoints();

  return (
    <Stack
      align="center"
      justify="center"
      css={({ layout }) => ({
        minHeight: "100vh",
        textAlign: "center",
        maxWidth: 740,
        margin: "0 auto",
        padding: layout.scale(6),
      })}
    >
      <Item>
        <Stack align="center" gap={8} vertical>
          <Item>
            <img
              height={60}
              alt="Lån.nu"
              src={process.env.PUBLIC_URL + "/lan-nu-logo.svg"}
            />
          </Item>
          <Item>
            <Text block variant="body1">
              If you're looking for great offers on loans, or maybe just
              some info about them, you have come to the right place.
            </Text>
          </Item>
          <Item>
            <Text block gutter variant="h6">
              Choose your country:
            </Text>
            <Stack
              align="center"
              gap={4}
              vertical={breakpoint === "xs" || !breakpoint}
            >
              <Item>
                <Button
                  href="/dk"
                  prefix={<FlagIcon countryCode="dk" size={6} />}
                  outlined
                >
                  Denmark
                </Button>
              </Item>
              <Item>
                <Button
                  href="/se"
                  prefix={<FlagIcon countryCode="se" size={6} />}
                  outlined
                >
                  Sweden
                </Button>
              </Item>
            </Stack>
          </Item>
        </Stack>
      </Item>
    </Stack>
  );
};
